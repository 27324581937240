import React from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { combineClassNames } from '@utils/combineClassNames';
import { PureList } from '@common/components/list';
import Spinner from '@common/components/spinner';
import { TableRow } from './table-row';
import { getSize } from './utils';
import type { LooseObject } from '@common/types/util-types';
import type { PureListProps } from '../list';
import dndManager from '@common/utils/dnd-manager';

type Column = {
  label?: React.ReactNode;
  className?: string;
  size?: string | number;
};

type ShowMoreComponentProps = {
  onShowMore: () => Promise<void>,
  isFetching?: boolean,
};

const TableShowMoreComponent = ({ onShowMore, isFetching }: ShowMoreComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="Table__ShowMore" onClick={!isFetching ? onShowMore : undefined} role="button">
      {isFetching ? <Spinner centered /> : t('common:table_show_more')}
    </div>
  );
};

type TableProps<
ItemType,
RowProps,
> = Omit<PureListProps<ItemType, RowProps>, 'renderRow'> & {
  columns: Column[];
  data: undefined;
  items: ItemType[];
  renderRow: (props: { item: ItemType, index: number } & RowProps) => React.ReactNode[];
  hideHeader?: boolean;
  borderless?: boolean;
  selectedRow?: any;
  onChangeOrder?: (targetId: string, itemId: string) => void;
  onDrop?: () => Promise<void>;
  ActionComponent?: React.ComponentType<{ item: ItemType, refetchItems: () => Promise<unknown> }>;
};

export function Table<
  ItemType extends LooseObject<{ id: string }>,
  RowProps extends Record<string, unknown>,
>(props: TableProps<ItemType, RowProps>) {
  const {
    columns,
    data,
    items,
    renderRow,
    rowProps,
    containerClassName,
    selectedRow,
    hideHeader,
    borderless,
    onChangeOrder,
    onDrop,
    ActionComponent,
    ...listProps
  } = props;
  const classNames = combineClassNames('Table', containerClassName, {
    'Table--borderless': borderless,
  });

  let ShowMoreComponent;
  if (data) ShowMoreComponent = TableShowMoreComponent;

  return (
    <DndProvider manager={dndManager}>
      <PureList
        containerClassName={classNames}
        items={items}
        header={(
          <div className={`Table__Row Table__Header${hideHeader ? ' Table__Header--hidden' : ''}`}>
            {onChangeOrder && <div className="Table__Cell Table__Action" />}
            {columns.map((column, index) => (
              <div
                key={column.label?.toString() || index}
                role="button"
                className="Table__Cell"
                // @ts-expect-error
                style={{ width: getSize(column.size, columns) }}
              >
                {!hideHeader && (
                  <h5>
                    {column.label}
                  </h5>
                )}
              </div>
            ))}
            {ActionComponent && <div className="Table__Cell Table__Action" />}
          </div>
        )}
        renderRow={TableRow}
        rowProps={{
          rowProps,
          columns,
          selectedRow,
          createCells: renderRow,
          onChangeOrder,
          onDrop,
          ActionComponent,
        }}
        {...listProps}
        // @ts-expect-error
        ShowMoreComponent={ShowMoreComponent}
      />
    </DndProvider>
  );
}
